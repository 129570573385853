import React from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import * as quiz from "../store/quiz";

// Components
import Countdown from "../components/Countdown";
import SoalNav from "../components/SoalNav";

import { FaCheck } from "react-icons/fa";
import SoalList from "../components/SoalList";

function Ujian() {
    const { soal, detail } = useSelector(quiz.selector);
    const dispatch = useDispatch();

    const handleSelesai = () => {
        console.log("selesai");
        let jawaban = soal.map((item) => {
            return {
                id_soal: item.id,
                jawaban: item.answer.selection || null,
            };
        });

        dispatch(
            quiz.sendAnswerToServer({
                id_ujian: detail.id_ujian,
                jawaban,
            })
        );
    };

    return (
        <div className="py-10 flex container md:px-7 mx-auto md:gap-7 flex-col xl:flex-row">
            <div className="w-full xl:w-[400px] flex md:gap-7 flex-col md:flex-row xl:flex-col flex-wrap">
                <div className="px-10 flex-full pb-10 md:pb-0">
                    <img
                        src="https://test.idolacasn.com/frontend/baru/images/logo.png"
                        alt=""
                    />
                </div>
                <div className="px-4 py-10 sm:px-6 bg-white shadow-lg sm:rounded-lg text-center justify-center flex flex-col gap-3 flex-1 xl:flex-none">
                    <div className="font-semibold">Waktu Tersisa</div>
                    <h3 className="text-3xl font-medium leading-6 text-gray-900">
                        <Countdown eventTime={detail.endTime} interval={1000} />
                    </h3>
                </div>
                <div className="px-4 py-5 sm:px-6 bg-white shadow-lg sm:rounded-lg text-center flex flex-col gap-4 flex-1 xl:flex-none">
                    Sudah Selesai?
                    <div className="flex gap-2 justify-center">
                        <a
                            href={`//api1.idolacasn.com/stopUjian.php?id_ujian=${detail.id_ujian}&id_user=${detail.id_user}`}
                            className="bg-red-500 text-white px-4 py-2 rounded"
                        >
                            Batal
                        </a>
                        <button
                            className="bg-green-500 text-white px-4 py-2 rounded"
                            onClick={handleSelesai}
                        >
                            Selesai
                        </button>
                    </div>
                </div>
                <div className="px-4 py-5 sm:px-6 bg-white shadow-lg sm:rounded-lg text-center flex flex-col gap-4 flex-1 xl:flex-none">
                    <div className="font-semibold">Nomor Soal</div>
                    <SoalList />
                </div>
            </div>
            <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg flex-1">
                <div className="px-4 py-5 sm:px-6 bg-[#b48560]">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-white">
                            {detail.nama_ujian}
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-300">
                            Jawablah pertanyaan dibawah ini sebaik mungkin!
                        </p>
                    </div>
                </div>
                <div className="border-t border-gray-200 p-5">
                    <h4>Soal no. {detail.step + 1}</h4>
                    <h1
                        className="sm:text-xl mb-10"
                        dangerouslySetInnerHTML={{
                            __html: soal[detail.step]?.question,
                        }}
                    ></h1>

                    {soal[detail.step]?.gambar && (
                            <img 
                                src={`https://test.idolacasn.com/upload/soal/img/${soal[detail.step].gambar}`} 
                                alt="Soal Gambar" 
                                className="mb-5 max-w-full h-auto object-contain"
                            />
                        )}

                    <div className="flex flex-col gap-2 m-6">
                        {soal[detail.step]?.answer.available.map((e) =>
                            e.value ? (
                                <div
                                    key={e.key}
                                    className="flex items-center gap-3 group cursor-pointer"
                                    onClick={() => {
                                        soal[detail.step]?.answer.selection ===
                                        e.key
                                            ? dispatch(quiz.unsetAnswer())
                                            : dispatch(quiz.setAnswer(e.key));
                                    }}
                                >
                                    <div
                                        className={`uppercase w-10 h-10 flex justify-center items-center rounded-full border-2 group-hover:border-blue-300 group-hover:text-blue-300 font-semibold  ${
                                            e.key ===
                                            soal[detail.step]?.answer.selection
                                                ? "text-green-500 border-green-500"
                                                : "text-gray-300 border-gray-300"
                                        }`}
                                    >
                                        {e.key ===
                                        soal[detail.step]?.answer.selection ? (
                                            <FaCheck />
                                        ) : (
                                            e.key
                                        )}
                                    </div>
                                    <div className="flex-1 text-sm">
                                        {e.value}
                                    </div>
                                </div>
                            ) : null
                        )}
                    </div>
                    <hr className="h-px my-4 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />
                    <div className="mb-10">
                        *Menemukan kesalahan pada soal?{" "}
                        <button
                            className="text-blue-600 hover:underline"
                            onClick={() => alert("awd")}
                        >
                            Laporkan
                        </button>
                    </div>
                    <SoalNav />
                </div>
            </div>
        </div>
    );
}

export default Ujian;
