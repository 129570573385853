import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as quiz from "../store/quiz";

export default function Welcome() {
    const dispatch = useDispatch();

    const { detail } = useSelector(quiz.selector);

    return (
        <div className="bg-white sm:bg-gray-100 mx-auto flex justify-center items-center h-screen">
            <div className="sm:bg-white w-96 rounded-lg sm:shadow-lg">
                <div className="p-10 px-20">
                    <img
                        src="https://test.idolacasn.com/frontend/baru/images/logo.png"
                        alt=""
                    />
                </div>
                <div className="flex flex-col gap-3 px-8">
                    <div>
                        <h3 className="text-gray-400 text-sm">Nama Peserta</h3>
                        <input
                            className="w-full border mt-1 text-gray-500 outline-none py-1 px-3 rounded-md"
                            value={detail?.nama}
                            type="text"
                            readOnly
                        />
                    </div>
                    <div>
                        <h3 className="text-gray-400 text-sm">Nama Ujian</h3>
                        <input
                            className="w-full border mt-1 text-gray-500 outline-none py-1 px-3 rounded-md"
                            value={detail?.nama_ujian}
                            type="text"
                            readOnly
                        />
                    </div>
                    <div>
                        <h3 className="text-gray-400 text-sm">Jumlah Soal</h3>
                        <input
                            className="w-full border mt-1 text-gray-500 outline-none py-1 px-3 rounded-md"
                            value={detail?.jumlah_soal}
                            type="text"
                            readOnly
                        />
                    </div>
                    <div>
                        <h3 className="text-gray-400 text-sm">
                            Waktu Mengerjakan
                        </h3>
                        <input
                            className="w-full border mt-1 text-gray-500 outline-none py-1 px-3 rounded-md"
                            value={detail?.waktu_penegerjaan}
                            type="text"
                            readOnly
                        />
                    </div>
                    <div className="text-sm">
                        <b className="text-red-500">*</b> Pada saat mengerjakan ujian, menutup tab atau browser dapat membatalkan ujian, tetapi tidak untuk refresh halaman
                    </div>
                </div>
                <div className="p-8 flex gap-2">
                    <button
                        onClick={() =>
                            dispatch(quiz.fetchQuizFromServer(detail))
                        }
                        className="border border-green-500 text-green-500 w-full py-2 rounded hover:bg-green-500 hover:text-white"
                    >
                        Mulai
                    </button>
                    <button
                        onClick={() => dispatch(quiz.batal())}
                        className="border border-red-500 text-red-500 w-full py-2 rounded hover:bg-red-500 hover:text-white"
                    >
                        Batal
                    </button>
                </div>
            </div>
        </div>
    );
}
